/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h3: "h3",
    blockquote: "blockquote",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "new Array(3).fill(0)\n")), "\n", React.createElement(_components.h3, null, "Push Method"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "\nconst LEN = 3 \nconst arr = []\nfor (let i=0; i<LEN; i++){\n  arr.push(0)\n}\n// [0,0,0]\n")), "\n", React.createElement(_components.h3, null, "Mapping method"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Personally I think the mapping methods are the cleanest and most versatile"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const arr = Array.from({length: 3}, () => 0)\n// [0, 0, 0]\n\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const arr = Array.from({length: 3}, (x,i) => i)\n// [0, 1, 2]\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
